import { Box, Container, fade, Link, Typography } from "@material-ui/core"
import React from "react"
import { CONTAINER_PADDING } from "../../gatsby-theme-material-ui-top-layout/theme"
import { useMobile } from "../../utils/hooks"
import Image from "../simple/Image"

const TwoColsSection = ({
  imgSrc,
  imgFilename,
  imgAlt,
  title,
  paragraph,
  actionText,
  actionUrl,
  aspectRatio,
  reversed,
  bgcolor,
  color,
  imageColProps: imageColPropsOverrides = {},
  ...props
}) => {
  const mobile = useMobile()

  let textColProps = {}

  if (mobile) {
    textColProps = {
      paddingBottom: "10vh",
      paddingTop: "10vh",
    }
  } else {
    textColProps = reversed
      ? {
          left: "120px",
        }
      : {
          right: "-143px",
        }
  }

  let imageColProps = { ...imageColPropsOverrides }
  if (mobile) {
    imageColProps = reversed
      ? {
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          ...imageColPropsOverrides,
        }
      : {
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          ...imageColPropsOverrides,
        }
  }

  const textCol = (
    <Box
      key="text"
      display="flex"
      alignItems="center"
      flexGrow={1}
      px={6}
      py={CONTAINER_PADDING}
      position="relative"
      zIndex={mobile ? 2 : undefined}
      color={color}
      style={{
        backgroundColor: color
          ? fade(color === "common.white" ? "#000" : "#fff", 0.14)
          : undefined,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={690}
        position={mobile ? undefined : "absolute"}
        zIndex={2}
        {...textColProps}
      >
        <Typography variant={mobile ? "h2" : "h1"} component="h3">
          {title}
        </Typography>

        <Typography
          style={{ maxWidth: "70%", marginTop: 54, marginBottom: 54 }}
        >
          {paragraph}
        </Typography>

        <Link to={actionUrl} style={{ color: "inherit" }} variant="h4">
          {actionText}
        </Link>
      </Box>
    </Box>
  )

  const imageCol = (
    <Box
      key="image"
      maxWidth={mobile ? "100%" : "50%"}
      display="flex"
      flexGrow={1}
      {...imageColProps}
    >
      <Image
        filename={imgFilename}
        src={imgSrc}
        alt={imgAlt}
        objectFit={mobile ? "cover" : undefined}
        imgStyle={
          mobile
            ? {
                height: "100%",
                width: "100%",
              }
            : {}
        }
      />
    </Box>
  )

  return (
    <Box
      display="flex"
      position="relative"
      flexDirection={mobile ? "column" : undefined}
      bgcolor={bgcolor}
      {...props}
    >
      {reversed ? [imageCol, textCol] : [textCol, imageCol]}
    </Box>
  )
}

export default TwoColsSection
