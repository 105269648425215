import { Box, Container, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import BrandCardList from "../components/common/BrandCardList/BrandCardList"
import StepperSection from "../components/sections/StepperSection"

import indexStepper1 from "../assets/svg/icon.svg"
import indexStepper2 from "../assets/svg/icon1.svg"
import indexStepper3 from "../assets/svg/icon2.svg"

import MilestoneSection from "../components/sections/MilestoneSection"
import TwoColsSection from "../components/sections/TwoColsSection"
import Tag from "../components/common/Tag"

import TagWithImagesSection from "../components/sections/TagWithImagesSection"

import LeafsCircleIcon from "../components/icons/LeafsCircleIcon"
import SectionContainer from "../components/layout/SectionContainer"
import {
  CONTAINER_PADDING,
  PINK_LIGHT_BG,
  YELLOW_BG,
} from "../gatsby-theme-material-ui-top-layout/theme"
import BrandCardGrid from "../components/common/BrandCardGrid"
import Layout from "../components/layout/Layout"
import { useMobile } from "../utils/hooks"

const steps = [
  { imgSrc: indexStepper1, title: "Wesprzyj projekt" },
  { imgSrc: indexStepper3, title: "Obserwuj transparentną produkcję" },
  { imgSrc: indexStepper2, title: "Bądź częścią marki" },
]

const ourMission = ["Zero waste", "Organic", "Wegan", "Local"]

//NEW', 'ONGOING', 'ENDED'

export const pageQuery = graphql`
  query {
    featuredBrands: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "brands" } }
        frontmatter: { featured: { eq: true } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            cardImage
            tags
          }
          fields {
            url
          }
        }
      }
    }
    newBrands: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "brands" } }
        frontmatter: { stage: { eq: "NEW" } }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            cardImage
            tags
          }
          fields {
            url
          }
        }
      }
    }

    ongoingBrands: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "brands" } }
        frontmatter: { stage: { eq: "ONGOING" } }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            cardImage
            tags
          }
          fields {
            url
          }
        }
      }
    }

    endedBrands: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "brands" } }
        frontmatter: { stage: { eq: "ENDED" } }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            cardImage
            tags
          }
          fields {
            url
          }
        }
      }
    }
  }
`

const IndexPage = ({
  data: { featuredBrands, newBrands, ongoingBrands, endedBrands },
}) => {
  const milestones = React.useMemo(
    () => [
      { stage: "NEW", title: "Aktualne projekty", items: newBrands.edges },
      { stage: "ONGOING", title: "Wkrótce start", items: ongoingBrands.edges },
      { stage: "ENDED", title: "Zakończone", items: endedBrands.edges },
    ],
    []
  )
  const mobile = useMobile()

  return (
    <Layout>
      <SectionContainer>
        <BrandCardGrid items={featuredBrands.edges} />
      </SectionContainer>

      <Box mt={mobile ? CONTAINER_PADDING / 2 : CONTAINER_PADDING} />

      <StepperSection
        imgTitle="Prosty dostęp do finansowania przyszłości"
        imgFilename={"stepper_pxadta.png"}
        steps={steps}
      />

      <Box mt={mobile ? CONTAINER_PADDING / 2 : CONTAINER_PADDING} />

      <Box bgcolor={PINK_LIGHT_BG}>
        {milestones.map((x, index) => (
          <MilestoneSection key={index + 1} {...x} number={index + 1} />
        ))}
      </Box>

      <TwoColsSection
        imgFilename={"split1_ta0z1x.png"}
        title="Rozwiń swoją twórczość"
        paragraph="Jesteś projektantem i wkładasz duszę w tworzenie wyjątkowych przedmiotów. Wyciągnij rękę do miłośników piękna i rozwiń produkcję twojej marki."
        actionText="utwórz projekt"
        actionUrl="/dla-projektantow"
        bgcolor={YELLOW_BG}
        color={mobile ? "common.white" : undefined}
        imageColProps={{ maxWidth: mobile ? "100%" : 700 }}
      />

      <TwoColsSection
        reversed
        imgFilename={"split2_edhhuh.png"}
        title="Wesprzyj projektanta"
        paragraph="Szukasz czegoś więcej niż kolorowe witryny. Zależy ci na naszej planecie i wspieraniu małych twórców. Odkryj jak możesz wziąć udział w ekspansji etycznego rzemiosła."
        actionText="załóż konto"
        actionUrl="/rejestracja"
        color={mobile ? "common.white" : undefined}
        imageColProps={{ maxWidth: mobile ? "100%" : 500 }}
      />

      <TagWithImagesSection
        imgFilename1={"tag1_cpkkql.png"}
        imgFilename2={"tag2_ixymbn.png"}
        bgcolor={"#E1D6D0"}
        tagContent={
          <>
            <Typography variant="h4">Nasza misja</Typography>

            <Box
              border="3px solid #E1D6D0"
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={3}
              px={2}
              mt={3}
            >
              <Box display="flex" flexDirection="column">
                {ourMission.map(x => (
                  <Box key={x} display="flex" alignItems="center">
                    <LeafsCircleIcon color="#E1D6D0" /> <Box ml={2} /> {x}
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        }
      />
    </Layout>
  )
}

export default IndexPage
