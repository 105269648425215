import {
  Box,
  makeStyles,
  Step,
  StepConnector,
  StepContent,
  StepIcon,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import { useMobile } from "../../utils/hooks"
import SectionContainer from "../layout/SectionContainer"
import BigNumber from "../simple/BigNumber"
import Image from "../simple/Image"

const ROZ_CIEMNU = "#DFC7C7"

const MOBILE_STEPPER_Y_OFFSET = 32

const useStyles = makeStyles({
  stepper: {
    background: "transparent",
    padding: 0,
  },
  stepperConnectorLine: {
    borderColor: "#EEEEEE",
  },
  step: {
    padding: 0,
  },
  stepIcon: {
    color: ROZ_CIEMNU,
    fontSize: "12px",
  },
  stepIconMobile: {
    position: "relative",
    top: 4,
    left: 6,
    transform: `translateY(${MOBILE_STEPPER_Y_OFFSET}px)`,
  },
  stepContentVertical: {
    position: "relative",
    border: "none",

    "&:before": {
      height: "100%",
      width: 1,
      backgroundColor: "#EEEEEE",
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      transform: `translateY(${MOBILE_STEPPER_Y_OFFSET}px)`,
    },
  },
})

const entity = amount => `calc(2vw * ${amount})`
const stepperSectionHeight = 70

const StepperSection = ({ steps, imgFilename, imgTitle }) => {
  const classes = useStyles()
  const mobile = useMobile()

  if (mobile) {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <SectionContainer position="relative" flexDirection="column">
          <Image filename={imgFilename} alt="header" />

          {imgTitle && (
            <Box
              display="flex"
              bgcolor="background.paper"
              p={entity(2)}
              ml="auto"
              mr="auto"
              width="calc(100% - 24px)"
              position="relative"
              top="-24px"
            >
              <Typography
                variant={mobile ? "h4" : "h3"}
                align="center"
                style={{
                  //position: "absolute",
                  width: "100%",
                  //top: "40%",
                  //left: "50%",
                  //transform: "translate(-50%, -50%)",
                }}
              >
                {imgTitle}
              </Typography>
            </Box>
          )}
        </SectionContainer>

        <SectionContainer flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            bgcolor="background.paper"
            position="relative"
            //top={`-${stepperSectionHeight}px`}
          >
            <Box display="flex" flexDirection="row" position="relative">
              <Stepper
                style={{ flexGrow: 1 }}
                orientation="vertical"
                connector={null}
              >
                {steps.map((x, index, arr) => (
                  <Step key={x.title + index} expanded active={false}>
                    <StepIcon
                      icon=""
                      classes={{
                        root: clsx(classes.stepIcon, classes.stepIconMobile),
                      }}
                    />

                    <StepContent
                      className={
                        arr.length - 1 !== index
                          ? classes.stepContentVertical
                          : undefined
                      }
                    >
                      <Box
                        display="flex"
                        flexGrow={1}
                        textAlign={"left"}
                        pb={4}
                      >
                        <BigNumber size="small">0{index + 1}</BigNumber>

                        <Box
                          flexGrow={1}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <img src={x.imgSrc} alt={x.title} />
                          <Box mt={2} maxWidth="200px">
                            <Typography align="center">{x.title}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </SectionContainer>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      <SectionContainer position="relative">
        <Image filename={imgFilename} alt="header" />

        {imgTitle && (
          <Typography
            variant="h3"
            align="center"
            style={{
              position: "absolute",
              width: "100%",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {imgTitle}
          </Typography>
        )}
      </SectionContainer>

      <SectionContainer flexDirection="column">
        <Box
          display="flex"
          flexDirection="column"
          mx={entity(2)}
          px={entity(2)}
          bgcolor="background.paper"
          position="relative"
          top={`-${stepperSectionHeight}px`}
        >
          <Box display="flex" position="relative" top={"-60px"} height="60px">
            {steps.map((x, index, arr) => (
              <Box
                key={x.title + index}
                flexGrow={1}
                textAlign={
                  index === 0
                    ? "left"
                    : index === arr.length - 1
                    ? "right"
                    : "center"
                }
              >
                <BigNumber>0{index + 1}</BigNumber>
              </Box>
            ))}
          </Box>

          <Stepper
            className={classes.stepper}
            connector={
              <StepConnector classes={{ line: classes.stepperConnectorLine }} />
            }
          >
            {steps.map(({ title, imgSrc }, index) => {
              const stepProps = {}
              const labelProps = {}

              return (
                <Step
                  key={title}
                  active={false}
                  classes={{ horizontal: classes.step }}
                >
                  <StepIcon icon="" classes={{ root: classes.stepIcon }} />
                </Step>
              )
            })}
          </Stepper>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          bgcolor="background.paper"
          px={entity(3)}
          py={`${stepperSectionHeight / 2}px`}
          mb={`-${stepperSectionHeight}px`}
          position="relative"
          top={`-${stepperSectionHeight}px`}
        >
          <Box display="flex" justifyContent="space-between">
            {steps.map((x, index, arr) => (
              <Box
                key={x.title + index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img src={x.imgSrc} alt={x.title} />
                <Box mb={2} />

                <Typography>{x.title}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </SectionContainer>
    </Box>
  )
}

export default StepperSection
