import React from "react"
import MasonryGrid from "../MasonryGrid"
import BrandCardListItem from "./BrandCardList/BrandCardListItem"

const BrandCardGrid = ({ items }) => {
  const _items = [...items, ...items, ...items].map((x, index) => {
    const data = x.node.frontmatter
    return (
      <BrandCardListItem
        key={index}
        image={data.cardImage}
        name={data.name}
        tags={data.tags}
        to={x.node.fields.url}
      />
    )
  })

  return <MasonryGrid items={_items} />
}

export default BrandCardGrid
