import { Box, Divider, makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  line: {
    flexGrow: 1,
  },
  arrow: {
    background: theme.palette.divider,
    height: 1,
    width: 30,
    position: "relative",

    "&:before, &:after": {
      content: "''",
      background: theme.palette.divider,
      position: "absolute",
      height: "1px",
      width: "15px",
    },

    "&:before": {
      right: "-4px",
      bottom: "-5px",
      transform: "rotate(-45deg)",
    },

    "&:after": {
      right: "-4px",
      top: "-5px",
      transform: "rotate(45deg)",
    },
  },
}))

const HorizontalLine = ({
  style,
  flexGrow,
  minWidth,
  maxWidth,
  width,
  endArrow,
}) => {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      alignItems="center"
      position="relative"
      {...{ minWidth, maxWidth, width, flexGrow }}
    >
      <Divider className={classes.line} style={style} />

      {endArrow && <div className={classes.arrow} />}
    </Box>
  )
}

HorizontalLine.defaultProps = {
  endArrow: false,
}

export default HorizontalLine
