import { Box } from "@material-ui/core"
import React from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import GatsbyImage from "./GatsbyImage"

const useStyles = makeStyles(theme => ({
  image: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    //objectFit: 'contain'
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  contentDefault: {
    width: "70%",
    height: "52%",
    top: "42%",
  },
  contentSquare: {
    bottom: "6%",
    height: "38%",
    width: "70%",
  },
}))

const Tag = ({
  className,
  classNames,
  maxWidth = 200,
  variant = "default",
  children,
}) => {
  const classes = useStyles()
  const imageFilename =
    variant === "square" ? "tag-square_hdnioy.png" : "tag_uapkcr.png"

  return (
    <Box
      display="flex"
      className={clsx(className)}
      maxWidth={maxWidth}
      width="100%"
    >
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <GatsbyImage
          filename={imageFilename}
          alt={""}
          className={clsx(classes.image, classNames.image)}
        />

        <Box
          className={clsx(classes.content, {
            [classes.contentDefault]: variant === "default",
            [classes.contentSquare]: variant === "square",
          })}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

Tag.defaultProps = {
  classNames: {},
}

export default Tag
