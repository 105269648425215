import { Box, Link, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import MasonryGrid from "../MasonryGrid"
import HorizontalLine from "../simple/HorizontalLine"
import BrandCardList from "../common/BrandCardList/BrandCardList"
import SectionContainer from "../layout/SectionContainer"
import BigNumber from "../simple/BigNumber"
import { CONTAINER_PADDING } from "../../gatsby-theme-material-ui-top-layout/theme"
import qs from "query-string"
import { mobile } from "../../utils/hooks"

const ViewMoreButton = ({ stage }) => {
  return (
    <Box py={3} display="flex" alignItems="center">
      <Link
        to={`/katalog?${qs.stringify(
          { stage: [stage] },
          { arrayFormat: "comma" }
        )}`}
        variant="h6"
      >
        zobacz wszyskie
      </Link>

      <HorizontalLine endArrow flexGrow={1} style={{ marginLeft: 32 }} />
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    position: "relative",
    [mobile(theme)]: {
      zIndex: 2,
    },

    "&:before": {
      content: "''",
      background: theme.palette.divider,
      position: "absolute",
      right: "calc(100% + 32px)",
      top: "50%",
      transform: "translateY(-50%)",
      height: "1px",
      width: "99999px",
    },
  },
}))

const MilestoneSection = ({ stage, number, title, items }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      py={CONTAINER_PADDING}
    >
      <Box display="flex" position="relative" zIndex={2} mb={CONTAINER_PADDING}>
        <SectionContainer alignItems={"center"}>
          <Typography
            variant={mobile ? "h2" : "h1"}
            component="h2"
            className={classes.title}
          >
            {title}
          </Typography>
        </SectionContainer>

        <BigNumber
          size={mobile ? "medium" : "large"}
          position="absolute"
          right={mobile ? 0 : "-55px"}
          bottom={mobile ? "60%" : undefined}
          zIndex={1}
        >
          0{number}
        </BigNumber>
      </Box>

      <SectionContainer flexDirection="column">
        <BrandCardList items={items} variant="square" />

        <Box mt={6}>
          <ViewMoreButton stage={stage} />
        </Box>
      </SectionContainer>
    </Box>
  )
}

export default MilestoneSection
