import { Box, makeStyles } from "@material-ui/core"
import React from "react"
import clsx from "clsx"
import { HEADERS_FONT_FAMILY } from "../../gatsby-theme-material-ui-top-layout/theme"

const useStyles = makeStyles(theme => ({
  root: {
    color: "#DFC7C7",
    fontFamily: HEADERS_FONT_FAMILY,
  },
  small: {
    fontSize: "59px",
    lineHeight: "59px",
  },
  medium: {
    fontSize: "96px",
    lineHeight: "96px",
  },
  large: {
    fontSize: "288px",
    lineHeight: "227px",
  },
}))

const BigNumber = ({ children, size = "medium", ...props }) => {
  const classes = useStyles()

  return (
    <Box
      component="span"
      className={clsx(classes.root, {
        [classes.small]: size === "small",
        [classes.medium]: size === "medium",
        [classes.large]: size === "large",
      })}
      {...props}
    >
      {children}
    </Box>
  )
}

export default BigNumber
