import { Box, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { mobile, useMobile } from "../../utils/hooks"
import ContainerFlex from "../common/ContainerFlex"
import Tag from "../common/Tag"
import SectionContainer from "../layout/SectionContainer"
import Image from "../simple/Image"

const useStyles = makeStyles(theme => ({
  tag: {
    zIndex: 2,
    marginLeft: "-108px",
    marginRight: "-102px",
    marginTop: "-180px",
    marginBottom: "-112px",

    [mobile(theme)]: {
      marginTop: "-95%",
      marginBottom: "-50%",
    },
  },
}))

const TagWithImagesSection = ({
  bgcolor,
  imgFilename1,
  imgFilename2,
  tagContent,
}) => {
  const classes = useStyles()
  const mobile = useMobile()

  return (
    <SectionContainer
      bgcolor={bgcolor}
      py={8}
      alignItems="center"
      flexDirection={mobile ? "column" : undefined}
    >
      <Image filename={imgFilename1} alt="" />
      <Tag className={classes.tag} maxWidth={318}>
        {tagContent}
      </Tag>
      <Image filename={imgFilename2} alt="" />
    </SectionContainer>
  )
}

export default TagWithImagesSection
